/*
	Overrides on styling in the react-codemirror2 component. Also, make it work with flexbox
*/

.react-codemirror2,
.CodeMirror,
.CodeMirror-scroll {
	display: -webkit-flex !important;
	display: flex !important;
	-webkit-flex: 1 1 auto !important;
	        flex: 1 1 auto !important;
	height: auto !important;
}

.react-codemirror2,
.CodeMirror {
	width: 100%;
}

.CodeMirror-gutters {
	background-color: #fff !important;
}

.error-mark {
	color:  var(--fds-color-state-message-error-color) !important;
	border-bottom: 2px solid var(--fds-color-state-message-error-color);
}

.highlight-mark {
	background-color: rgba(173, 218, 255, 0.5);
}


